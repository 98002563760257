import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Modals } from "store/Modals/types";
import { SubmitEmailModal } from "../SubmitEmailModal";
import { useDispatch } from "react-redux";
import { setActiveModal } from "store/Modals/slice";
import { IFrameType } from "store/VideoSession/types";
import { useHandleRestore } from "hooks/useHandleRestore";

export const RestorePurchaseModal: React.FC = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | undefined>();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((s: RootState) => s.loading);
    const { iframeType, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );

    const handleRestore = useHandleRestore();
    const restoreAccess = (email: string) => {
        handleRestore(email, {
            postRestore: () => {
                dispatch(setActiveModal(Modals.PurchaseRestored));
            },
            onError: () => {
                setError(t("errors:restore-purchase"));
            }
        });
    };

    return (
        <SubmitEmailModal
            title={t("modals:restore-title")}
            description={t("modals:restore-description")}
            buttonText={t("buttons:restore-access")}
            preventCloseOnSuccess={true}
            error={error}
            onSuccess={restoreAccess}
            onClose={() => {
                iframeType === IFrameType.AuxModal &&
                    parentFrame?.closePurchaseModal();
            }}
            isLoading={isLoading}
        />
    );
};
