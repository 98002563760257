import store from "store/store";
import { IFetchOptions } from "../types";

const baseUrl =
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net";

export default async function paymentFetch(
    options: IFetchOptions,
    uri = baseUrl
) {
    const url = `${uri}${options.resource}?${parseFields(options)}`;

    // add headers if not present.
    options.headers ||= {};

    const state = store.getState();
    const scopedBearerToken =
        state.creatorCustomers[
            state.playerCreator?.details?.stripeAccountId ?? ""
        ]?.ticket?.access_token;
    if (scopedBearerToken) {
        (options.headers as { [key: string]: string })["Authorization"] =
            "bearer " + scopedBearerToken;
    }

    try {
        const response = await fetch(url, {
            method: options.method || "GET",
            body: JSON.stringify(options.body) || null,
            headers: {
                ...options.headers,
                "Content-Type": "application/json"
            }
        });

        const body = await handleResponse(response);

        if (response.status >= 400) {
            throw new Error(body?.error?.message);
        }

        return body;
    } catch (e) {
        throw e;
    } finally {
    }
}

async function handleResponse(response: Response) {
    const text = await response.text();

    try {
        let result = null;
        result = text === "" ? null : JSON.parse(text);

        return result;
    } catch (e) {
        throw new Error("Invalid JSON");
    }
}

function parseFields(options: IFetchOptions) {
    let fieldsArray: string[] = [];
    for (let i in options.fields) {
        // if field has value add it to array
        if (options.fields[i]) {
            fieldsArray.push(`${i}=${encodeURIComponent(options.fields[i])}`);
        }
    }
    return fieldsArray.join("&");
}
