import {
    CollectionVideoResponse,
    CollectionVideosResponse,
    DefaultInteractiveTab,
    EntitlementPriceResponse,
    VideoPlayerAspectRatio
} from "@switcherstudio/player-api-client";
import { AdaptationEvent } from "components/ShakaPlayer/types";
import { ICreatorCustomerTicket } from "store/CreatorCustomers/types";
import { Modals } from "store/Modals/types";

export type QualityLevelOptions = number | "auto";
export type VideoSpeed = number | "Normal";

export type VideoSessionState = {
    upcomingCollectionVideos: CollectionVideosResponse | undefined;
    currentCollectionVideo: CollectionVideoResponse | undefined | null;
    sessionId: string;
    video: HTMLVideoElement | null;
    playHasStarted: boolean;
    isPlaying: boolean;
    isEnded: boolean;
    isEmbed: boolean;
    referrerUrl: string | null;
    parentFrame: ParentFrame | null;
    isExpanded: boolean;
    isVoD: boolean;
    isToolDrawerActive: boolean;
    activeTool: DefaultInteractiveTab;
    selectedPrice: EntitlementPriceResponse | undefined;
    activeContextMenu: ContextMenus;
    liveEventEndedByNextEventAt: number | null | undefined;
    levelLoadError: boolean;
    /** Stores the last quality level a user actively selected */
    userSelectedQualityLevel: QualityLevelOptions;
    /** Stores the available quality level that best matches the users previous selection */
    constrainedSelectedQualityLevel: QualityLevelOptions;
    /** Stores the last speed level a user actively selected */
    userSelectedSpeed: VideoSpeed;
    volume: number;
    isMuted: boolean;
    /** Marks if user is seeking manually or not */
    userSeeking: boolean;
    /** Used for Shaka Player quality */
    shakaVideoQuality: {
        levelInfo: AdaptationEvent;
        isAuto: boolean;
    };
    buffering: boolean;
    isInitialPlay: boolean;
    /** When set to a number, allows interval to run updating time slider value from current video time
     * When null, time slider value is controlled manually via user interactions
     */
    timeSliderDelay: number | null;
    heartbeatDelay: number | null;
    isFullScreen: boolean;
    isInitialBuffering: boolean;
    videoJsAdapter: any;
    seekingStart: number | undefined | null;
    streamingProtocol: "dash" | "hls" | undefined;
    isAutoPlay: boolean;
    hasSetDefault: boolean;
    preselectedPlaylistBroadcastId: string | null;
    iframeType: IFrameType | null;
    isIframeVisible: boolean;
    isMicrosite: boolean;
};

export enum IFrameType {
    Main = "main",
    AuxPlayer = "aux-player",
    AuxModal = "aux-modal"
}

export enum ContextMenus {
    None,
    MoreOptions,
    QualityOptions,
    PlaybackSpeedOptions
}

export interface ParentFrame {
    redirect: (code: string) => void;
    openPlayer: (
        catalogId: string,
        playerId: string,
        playlistBroadcastId?: string,
        autoPlay?: boolean
    ) => void;
    closePlayer: () => void;
    openPurchaseModal: (
        type: Modals,
        catalogId: string,
        playerId: string
    ) => void;
    closePurchaseModal: () => void;
    expand: () => void;
    collapse: (
        aspectRatio: VideoPlayerAspectRatio | undefined,
        maxWidth: string | undefined
    ) => void;
    setAspectRatio: (AspectRatio?: VideoPlayerAspectRatio) => void;
    resize: (height?: number) => void;
    distributeAuthorization: (ticket: ICreatorCustomerTicket) => void;
    emitEvent: (eventName: string, eventData: any) => void;
    setDialogBackgroundColor: (color: string) => void;
    setMaxWidth: (maxWidth?: string) => void;
}
