import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useMemo } from "react";
import { CollectionVideoResponse } from "@switcherstudio/player-api-client";
import useCollectionWithVideos from "./useCollectionWithVideos";

export const useGatedContent = (
    specificBroadcast?: CollectionVideoResponse
) => {
    const { collection } = useCollectionWithVideos();

    const { currentCollectionVideo, isEmbed, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );
    const { catalog } = useSelector((s: RootState) => s.catalogState);

    const broadcastToCheck = useMemo(
        () => specificBroadcast ?? currentCollectionVideo,
        [specificBroadcast, currentCollectionVideo]
    );

    const catalogIsGated = useMemo(() => catalog?.isGated ?? false, [catalog]);
    const catalogIsEntitled = useMemo(
        () => catalog?.isEntitled ?? false,
        [catalog]
    );
    const catalogRequiresPurchase = useMemo(
        () => catalogIsGated && !catalogIsEntitled,
        [catalogIsEntitled, catalogIsGated]
    );

    const catalogEntitlements = useMemo(() => {
        return (
            catalog?.entitlements ??
            collection?.entitlements?.filter(
                (entitlement) => entitlement?.details?.type === "Catalog"
            ) ??
            []
        );
    }, [catalog, collection]);

    const catalogInvoices = useMemo(() => {
        return (
            catalogEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [catalogEntitlements]);

    const playerIsGated = useMemo(
        () => collection?.isGated ?? false,
        [collection]
    );
    const playerIsEntitled = useMemo(
        () => collection?.isEntitled ?? false,
        [collection]
    );
    const playerRequiresPurchase = useMemo(
        () => playerIsGated && !playerIsEntitled,
        [playerIsEntitled, playerIsGated]
    );
    const playerEntitlements = useMemo(() => {
        return (
            collection?.entitlements?.filter(
                (entitlement) => entitlement?.details?.type === "Collection"
            ) ?? []
        );
    }, [collection]);

    const playerInvoices = useMemo(() => {
        return (
            playerEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [playerEntitlements]);

    const playlistBroadcastIsGated = useMemo(
        () => broadcastToCheck?.isGated ?? false,
        [broadcastToCheck]
    );

    const playlistBroadcastIsGatedIndividually = useMemo(
        () =>
            !!broadcastToCheck?.entitlements?.length &&
            broadcastToCheck?.entitlements.length > 0,
        [broadcastToCheck]
    );

    const playlistBroadcastIsEntitled = useMemo(
        () => broadcastToCheck?.isEntitled ?? false,
        [broadcastToCheck]
    );

    const playlistBroadcastRequiresPurchase = useMemo(
        () =>
            playlistBroadcastIsGatedIndividually &&
            !playlistBroadcastIsEntitled,
        [playlistBroadcastIsEntitled, playlistBroadcastIsGatedIndividually]
    );

    const hasFeaturedVideoAndIdleStateIsFeatured = useMemo<boolean>(
        () =>
            (collection?.details?.idleState === "SelectVideo" &&
                broadcastToCheck?.broadcast?.details?.id ===
                    collection?.details?.idleBroadcastId) ??
            false,
        [collection, broadcastToCheck]
    );

    const playlistBroadcastEntitlements = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            broadcastToCheck?.entitlements?.filter(
                (entitlement) => entitlement?.details?.type === "Video"
            ) ?? []
        );
    }, [broadcastToCheck, hasFeaturedVideoAndIdleStateIsFeatured]);

    const playlistBroadcastInvoices = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            playlistBroadcastEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [playlistBroadcastEntitlements, hasFeaturedVideoAndIdleStateIsFeatured]);

    const creator = useSelector((s: RootState) => s.playerCreator);
    const creatorCustomers = useSelector((s: RootState) => s.creatorCustomers);
    const customerEmail = useMemo(
        () =>
            creatorCustomers[creator?.details?.stripeAccountId ?? ""]?.ticket
                ?.email,
        [creator, creatorCustomers]
    );
    const gatedContentDisabled = useMemo<boolean>(
        () => isEmbed && !parentFrame,
        [isEmbed, parentFrame]
    );

    const userHasSubscription = useMemo<boolean>(() => {
        return catalogInvoices?.length > 0 || playerInvoices?.length > 0;
    }, [catalogInvoices, playerInvoices]);

    const availableEntitlements = useMemo(() => {
        if (catalogInvoices?.length > 0) {
            return [];
        }
        if (playerInvoices?.length > 0) {
            return catalogEntitlements;
        }
        if (playlistBroadcastInvoices?.length > 0) {
            return [...catalogEntitlements, ...playerEntitlements];
        }
        return [
            ...catalogEntitlements,
            ...playerEntitlements,
            ...playlistBroadcastEntitlements
        ];
    }, [
        catalogEntitlements,
        catalogInvoices,
        playerEntitlements,
        playerInvoices,
        playlistBroadcastEntitlements,
        playlistBroadcastInvoices
    ]);

    return {
        catalogIsGated,
        catalogIsEntitled,
        /** Indicates that the catalog is gated and has not been purchased */
        catalogRequiresPurchase,
        catalogEntitlements,
        catalogInvoices,
        playerIsGated,
        playerIsEntitled,
        /** Indicates that the player is gated and has not been purchased */
        playerRequiresPurchase,
        playerEntitlements,
        playerInvoices,
        playlistBroadcastIsGated,
        /** Indicates that the broadcast is gated and has not been purchased */
        playlistBroadcastRequiresPurchase,
        /** Broadcast is gated from its own entitlement, not including player entitlements */
        playlistBroadcastIsGatedIndividually,
        playlistBroadcastIsEntitled,
        playlistBroadcastEntitlements,
        playlistBroadcastInvoices,
        customerEmail,
        gatedContentDisabled,
        userHasSubscription,
        availableEntitlements
    };
};
