import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useRedirectUrl } from "./useRedirectUrl";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
import { AppDispatch } from "../store/store";
import { setIsLoading } from "store/Loading/slice";
import { StripeCheckoutModal } from "components/Modals/StripeCheckoutModal";
import { PurchaseSuccessModal } from "components/Modals/PurchaseSuccessModal";
import {
    EntitlementPriceResponse,
    EntitlementProductResponse
} from "@switcherstudio/player-api-client";
import { payments } from "api/payment/client";

export const useHandlePurchasePass = (
    product: EntitlementProductResponse | undefined,
    selectedPrice: EntitlementPriceResponse | undefined,
    currentBroadcastId: string | undefined
) => {
    const { details } = useSelector((s: RootState) => s.playerCreator);
    const dispatch = useDispatch<AppDispatch>();

    const redirectUrl = useRedirectUrl(currentBroadcastId);

    const createCheckout = useCallback(
        async (email: string) => {
            try {
                const checkoutSessionResponse =
                    await payments.CreateStripeCheckout({
                        EmailAddress: email,
                        PriceId: selectedPrice?.details?.id,
                        RedirectUrl: redirectUrl.href,
                        IsEmbeddedCheckout: true,
                        IsEmbed: true // we are always in an embed context now.
                    });

                if (!checkoutSessionResponse.StripeClientSecret) {
                    throw new Error("StripeClientSecret is not defined");
                }

                dispatch(
                    setActiveModal({
                        id: Modals.StripeCheckout,
                        type: Modals.StripeCheckout,
                        component: (
                            <StripeCheckoutModal
                                stripeCheckoutSecret={
                                    checkoutSessionResponse.StripeClientSecret
                                }
                                stripeAccountId={details?.stripeAccountId ?? ""}
                                checkoutSessionId={
                                    checkoutSessionResponse.CheckoutSessionId
                                }
                                creatorStripeAccountId={
                                    checkoutSessionResponse.CreatorStripeId
                                }
                            />
                        )
                    })
                );

                dispatch(setIsLoading(false));
            } catch (e) {
                dispatch(setIsLoading(false));
                dispatch(setActiveModal(Modals.None));
            }
        },
        [selectedPrice?.details?.id, redirectUrl.href, dispatch, details]
    );

    const tryRetrievePurchaseOrCheckout = useCallback(
        async (email: string) => {
            try {
                dispatch(setIsLoading(true));
                await payments.RestorePurchase({
                    EmailAddress: email,
                    ProductIds: [product?.details?.id ?? ""],
                    RedirectUrl: redirectUrl.href,
                    IsEmbed: true // we are always in an embed context now.
                });

                dispatch(
                    setActiveModal({
                        id: Modals.PurchaseFoundAndRestored,
                        type: Modals.PurchaseFoundAndRestored,
                        component: (
                            <PurchaseSuccessModal
                                activeModal={Modals.PurchaseFoundAndRestored}
                                customerEmail={email}
                            />
                        )
                    })
                );
            } catch (e) {
                createCheckout(email);
            }
            dispatch(setIsLoading(false));
        },
        [createCheckout, dispatch, product?.details?.id, redirectUrl.href]
    );

    return {
        handlePurchase: tryRetrievePurchaseOrCheckout
    };
};
