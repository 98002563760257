import React, { useCallback, useRef } from "react";
import { useKeyPress } from "hooks/useKeyPress";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
import CloseIcon from "assets/icons/close.svg?react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export interface GenericModalProps {
    successButton?: string;
    dismissButton?: string;
    title: string;
    onSuccess?: (...args: any) => any;
    onClose?: () => void;
    shouldHideButtons?: boolean;
    successButtonDisabled?: boolean;
    preventDismiss?: boolean;
    preventCloseOnSuccess?: boolean;
    children?: React.ReactNode;
    variant?: "stripe-checkout-embed";
}

export const GenericModal: React.FC<GenericModalProps> = ({
    successButton,
    dismissButton,
    title,
    onSuccess,
    onClose,
    successButtonDisabled = false,
    shouldHideButtons,
    children,
    preventDismiss,
    preventCloseOnSuccess = false,
    variant
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const modalRef = useRef(null);

    const close = useCallback(() => {
        if (!preventDismiss) {
            dispatch(setActiveModal(Modals.None));
            onClose && onClose();
        }
    }, [dispatch, onClose, preventDismiss]);

    const handleClose: React.MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            close();
        },
        [close]
    );

    const success: React.MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (onSuccess) {
                onSuccess();
            }
            if (!preventCloseOnSuccess) {
                close();
            }
        },
        [close, onSuccess, preventCloseOnSuccess]
    );
    useKeyPress("Escape", close);

    return (
        <div className={cx("modal-wrapper")} onClick={close}>
            <div
                className={`${cx("modal-container")} ${
                    variant ? cx(variant) : ""
                }`}
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={cx("modal-header")}>
                    {title}
                    <button
                        title="Close"
                        className={cx("modal-close")}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className={`modal-body ${cx("modal-body")}`}>
                    {children}
                </div>
                {!shouldHideButtons && (successButton || dismissButton) && (
                    <div className={`modal-footer ${cx("modal-footer")}`}>
                        <div className={cx("buttons")}>
                            {dismissButton && (
                                <button
                                    className={`btn btn-primary`}
                                    onClick={close}
                                >
                                    {dismissButton}
                                </button>
                            )}
                            {successButton && onSuccess && (
                                <button
                                    className={`btn btn-primary ${cx({
                                        "full-width": !dismissButton
                                    })}`}
                                    onClick={success}
                                    disabled={successButtonDisabled}
                                >
                                    {successButton}
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
