import React, { useMemo } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { DMCAModal } from "./DmcaModal";
import { ReportModal } from "./ReportModal";
import { RestorePurchaseModal } from "./RestorePurchaseModal";
import { PurchaseSuccessModal } from "./PurchaseSuccessModal";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Modals } from "store/Modals/types";
import { PurchaseOptionsModal } from "./PurchaseOptionsModal";

export const ModalManager = () => {
    const { activeModal, modalData } = useSelector((s: RootState) => s.modals);
    const { confirmationOpen } = useSelector((s: RootState) => s.confirmation);

    const currentModal = useMemo(() => {
        /** TODO: refactor confirmation modal to use active modal state instead of confirmation open */
        if (confirmationOpen) {
            return <ConfirmationModal />;
        }

        if (activeModal?.component) {
            return activeModal?.component;
        }

        switch (activeModal?.type) {
            case Modals.DMCA:
                return <DMCAModal />;
            case Modals.Report:
                return <ReportModal />;
            case Modals.PurchaseOptions:
                return <PurchaseOptionsModal />;
            case Modals.RestorePurchase:
                return <RestorePurchaseModal />;
            case Modals.PurchaseRestored:
                return (
                    <PurchaseSuccessModal
                        activeModal={activeModal?.type}
                        customerEmail={modalData?.email ?? ""}
                    />
                );
            case Modals.None:
            default:
                return <></>;
        }
    }, [activeModal, confirmationOpen, modalData]);

    return currentModal;
};
