import React from "react";
import styles from "./index.module.scss";

export const NonbrandedLoading = () => (
    <div className={styles["loading-container"]}>
        <div className={styles["dot"]} />
        <div className={styles["dot"]} />
        <div className={styles["dot"]} />
    </div>
);
