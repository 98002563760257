import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestAccessButtonGroup } from "components/Buttons/RequestAccessButtonGroup";
import {
    EntitlementResponse,
    ProductMetaDataDetailsResponse
} from "@switcherstudio/player-api-client";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
export interface PurchaseOptionsProps {
    entitlement: EntitlementResponse | undefined;
    disabled?: boolean;
    title?: string;
    entityId?: string | undefined;
    email: string;
    metadata: ProductMetaDataDetailsResponse | undefined;
    setError?: (error: string) => void;
}

export const PurchaseOptions: React.FC<PurchaseOptionsProps> = ({
    entitlement,
    disabled,
    title,
    entityId,
    email,
    metadata,
    setError
}) => {
    const { t } = useTranslation();
    const cx = classNames.bind(styles);

    const getLastUpdatedText = useCallback(
        (lastUpdated: Date) => {
            const now = new Date();
            const updatedDate = new Date(lastUpdated);
            const diffInDays = Math.floor(
                (now.getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24)
            );
            let result;

            switch (true) {
                case diffInDays === 0:
                    result = t("time:today");
                    break;
                case diffInDays === 1:
                    result = t("time:yesterday");
                    break;
                case diffInDays < 7:
                    result = t("time:this-week");
                    break;
                case diffInDays < 14:
                    result = t("time:last-week");
                    break;
                case updatedDate.getMonth() === now.getMonth():
                    result = t("time:this-month");
                    break;
                case updatedDate.getMonth() === now.getMonth() - 1:
                    result = t("time:last-month");
                    break;
                case updatedDate.getFullYear() === now.getFullYear() &&
                    updatedDate.getMonth() === now.getMonth():
                    result = t("time:this-year");
                    break;
                case updatedDate.getFullYear() === now.getFullYear() - 1:
                    result = t("time:last-year");
                    break;
                default:
                    result = t("time:year-plus");
                    break;
            }

            return `${t("metadata:last-updated")} ${result}`;
        },
        [t]
    );

    const metaInfo = useMemo(() => {
        if (!metadata) return null;
        const { entitlementType, lastUpdated, totalCollections, totalVideos } =
            metadata;

        const infoArray = [];

        if (
            entitlementType === "Catalog" &&
            typeof totalCollections !== "undefined"
        ) {
            infoArray.push(
                `${totalCollections} ${
                    totalCollections > 1
                        ? t("metadata:collections")
                        : t("metadata:collection")
                }`
            );
        }

        if (
            entitlementType === "Collection" &&
            totalCollections &&
            totalCollections > 1
        ) {
            infoArray.push(
                `${t("metadata:and")} ${totalCollections - 1} ${t("metadata:other")} ${
                    totalCollections - 1 > 1
                        ? t("metadata:collections")
                        : t("metadata:collection")
                }`
            );
        }

        if (
            (entitlementType === "Catalog" ||
                entitlementType === "Collection") &&
            totalVideos &&
            totalVideos > 0
        ) {
            infoArray.push(
                `${totalVideos} ${totalVideos > 1 ? t("metadata:videos") : t("metadata:video")}`
            );
        }

        if (entitlementType === "Video" && totalVideos && totalVideos > 1) {
            infoArray.push(
                `${t("metadata:and")} ${totalVideos - 1} ${t("metadata:other")} ${
                    totalVideos - 1 > 1
                        ? t("metadata:videos")
                        : t("metadata:video")
                }`
            );
        }

        if (entitlementType !== "Video" && lastUpdated) {
            infoArray.push(getLastUpdatedText(lastUpdated));
        }

        const metaText = infoArray.join(" • ").replace(/\s+•\s*$/, "");

        return <small>{metaText}</small>;
    }, [getLastUpdatedText, metadata, t]);

    const passDescription = useMemo(() => {
        return entitlement?.product.details.description;
    }, [entitlement]);

    return (
        <div className={cx("section-container")}>
            <span className={cx("section-heading")}>{title}</span>
            <span className={cx("meta")}>{metaInfo}</span>
            <span className={cx("pass-description")}>
                <small>{passDescription}</small>
            </span>
            <RequestAccessButtonGroup
                disabled={disabled}
                entitlement={entitlement}
                entityId={entityId}
                email={email}
                setError={setError}
            />
        </div>
    );
};
