export const getRollbarConfig = () => {
    const globalEnvironment = import.meta.env.VITE_ENV || "local";
    const globalAppVersion = import.meta.env.VITE_VERSION || "1.0";

    return {
        accessToken:
            import.meta.env.VITE_ROLLBAR_CLIENTKEY ||
            "6fa16027e9914a17a9a561b94b59d2bc",
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: globalEnvironment === "local" ? false : true,
        environment: globalEnvironment,
        hostSafeList: [
            "player-develop.switcherstudio.com",
            "player-stage.switcherstudio.com",
            "player.switcherstudio.com"
        ],
        payload: {
            environment: globalEnvironment,
            client: {
                javascript: {
                    code_version: globalAppVersion,
                    source_map_enabled: true,
                    guess_uncaught_frames: true
                }
            }
        },
        ignoredMessages: [/Error: Unable to preload CSS for \/assets\/.*\.css/i]
    };
};

export default getRollbarConfig;
