import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import {
    CreatorCustomersState,
    ICreatorCustomerTicket,
    RefreshTokenPayload
} from "./types";
import { exchangeCode, refreshToken } from "./thunks";

const initialState = {} as CreatorCustomersState;

export const creatorCustomersSlice = createSlice({
    name: "creatorCustomers",
    initialState: initialState,
    reducers: {
        setCreatorCustomerTicket: (
            state,
            action: PayloadAction<ICreatorCustomerTicket>
        ) => {
            const creatorStripeAccountId = action.payload.stripeAccountId;
            if (creatorStripeAccountId) {
                state[creatorStripeAccountId] = {
                    ticket: action.payload
                };
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(exchangeCode.fulfilled, (state, action) => {
            const creatorStripeAccountId = action.payload.stripeAccountId;
            if (creatorStripeAccountId) {
                state[creatorStripeAccountId] = {
                    ticket: action.payload
                };
            }
        });

        builder.addCase(
            refreshToken.fulfilled,
            (state, { payload }: PayloadAction<RefreshTokenPayload>) => {
                state[payload?.creatorStripeId] = { ticket: payload.ticket };
            }
        );

        builder.addCase(exchangeCode.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(refreshToken.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export const { setCreatorCustomerTicket } = creatorCustomersSlice.actions;

export default creatorCustomersSlice.reducer as Reducer<CreatorCustomersState>;
