import { EmailInput } from "components/inputs/text-input/EmailInput";
import { validEmail } from "helpers/email";
import { useState } from "react";
import { GenericModal } from "../GenericModal";
import { useGatedContent } from "hooks/useGatedContent";
import styles from "../PurchaseSuccessModal/index.module.scss";

export interface SubmitEmailModalProps {
    title: string;
    description: string;
    buttonText: string;
    error?: string;
    preventCloseOnSuccess?: boolean;
    onSuccess: (email: string) => void;
    onClose?: () => void;
    isLoading: boolean;
}

/** A modal to handle email input */
export const SubmitEmailModal: React.FC<SubmitEmailModalProps> = ({
    title,
    description,
    buttonText,
    error,
    preventCloseOnSuccess = false,
    onSuccess,
    onClose,
    isLoading = false
}) => {
    const { customerEmail } = useGatedContent();
    const [email, setEmail] = useState<string | null>(null);

    return (
        <GenericModal
            title={title}
            successButton={buttonText}
            onSuccess={() => onSuccess(email ?? "")}
            onClose={onClose}
            successButtonDisabled={!validEmail(email ?? "") || isLoading}
            preventCloseOnSuccess={preventCloseOnSuccess}
            preventDismiss={isLoading}
        >
            <div className={`${isLoading ? styles.isLoading : ""}`}>
                <p>{description}</p>
                <EmailInput
                    value={email ?? customerEmail ?? ""}
                    onChange={(event) => setEmail(event.target.value)}
                    error={error}
                    isLoading={isLoading}
                />
            </div>
        </GenericModal>
    );
};
